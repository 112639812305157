/**
 * Класс для работы с локальным хранилищем с использованием JSON
 */
export class Storage<T> {
	#name: string;

	constructor(name: string) {
		this.#name = name;
	}

	/**
	 * Возвращает объект хранилища
	 */
	#getStoredObject = () => {
		const storedValue = localStorage.getItem(this.#name);
		return storedValue ? (JSON.parse(storedValue) as Partial<T>) : null;
	};

	/**
	 * Изменяет объект хранилища
	 * @param newValue новый объект хранилища
	 */
	#setStoredObject = (newValue: Partial<T>) => {
		if (Object.keys(newValue).length === 0) {
			localStorage.removeItem(this.#name);
		} else {
			localStorage.setItem(this.#name, JSON.stringify(newValue));
		}
	};

	/**
	 * Возвращает значение свойства из хранилища
	 * @param key название свойства объекта хранилища
	 */
	get = <P>(key: keyof T): P | null => {
		const storedObject = this.#getStoredObject();
		if (!storedObject) return null;
		return storedObject[key] as P;
	};

	/**
	 * Устанавливает значение параметра в хранилище
	 * @param part часть объекта хранилища
	 */
	set = (part: Partial<T>) => {
		const storedObject = this.#getStoredObject();
		const newObject = { ...storedObject, ...part };
		this.#setStoredObject(newObject);
	};

	/**
	 * Выполняет "умную" установку параметра:
	 * - если значение присутствует, то устанавливает его в хранилище;
	 * - если значение отсутствует, то удаляет иго из хранилища.
	 * @param key название параметра
	 * @param value новое значение параметра
	 */
	setSmart = (key: keyof T, value: any) => {
		if (!!value) {
			this.set({ [key]: value } as Partial<T>);
		} else {
			const storedObject = this.#getStoredObject();
			if (!storedObject) return;
			const newObject = { ...storedObject };
			delete newObject[key];
			this.#setStoredObject(newObject);
		}
	};
}
