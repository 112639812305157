import React, { useCallback, useRef, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { BsUpload } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { showError } from '../../alerts';
import { DEFAULTS } from '../../common';
import VerifiedMark from '../../components/VerifiedMark';
import WithActiveUserData, { IWithActiveUserData } from '../../components/hoc/withActiveUserData';
import WithRealms, { IWithRealms } from '../../components/hoc/withRealms';
import { TEntityId } from '../../interfaces/common';
import UploaderFileSelector from './UploaderFileSelector';

interface IUploadButtonProps extends IWithRealms, IWithActiveUserData {
	realms: TEntityId<string>[];
}
const UploadButton = ({ realmsById, realms, activeUserData }: IUploadButtonProps) => {
	const refFileSelector = useRef<HTMLInputElement>(null);
	const [realm, setRealm] = useState<string>();
	const navigate = useNavigate();

	const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		const { realm } = event.currentTarget.dataset;
		if (!realm) return;
		// event.preventDefault();
		// event.stopPropagation();

		if (
			!realmsById[realm]?.draft &&
			activeDocumentsLimitReached(activeUserData?.incompleteDocumentsCount, activeUserData?.incompleteDocumentMaxAge)
		)
			return;

		setRealm(realm);
		// Без таймауту не встигає оновлюватись інтерфейс
		setTimeout(() => {
			refFileSelector.current?.click();
		}, 100);
	};

	const onUploadDone = useCallback(
		(documentHash?: string) => {
			if (documentHash && realm && realmsById[realm]?.draft === false) navigate(`/doc/${documentHash}`);
		},
		[realm, navigate, realmsById]
	);

	if (realms.length === 0) return null;

	return (
		<>
			<NavDropdown
				title={
					<>
						<BsUpload className="icon-lg" />
						<span className="ms-2 d-md-none d-lg-inline">Завантажити</span>
					</>
				}
				id="ddUpload"
			>
				{realms.map((realm) => (
					<NavDropdown.Item key={realm} href="#" onClick={onClick} data-realm={realm}>
						<VerifiedMark draft={realmsById[realm]?.draft} title={realmsById[realm]?.title} className="mt-n1 me-1" />
						{realmsById[realm]?.title}
					</NavDropdown.Item>
				))}
			</NavDropdown>
			{realm && (
				<UploaderFileSelector
					onUploadDone={onUploadDone}
					realm={realm}
					title={realmsById[realm]?.title}
					autoClose={!realmsById[realm]?.draft}
					isDraft={!!realmsById[realm]?.draft}
					ref={refFileSelector}
				/>
			)}
		</>
	);
};

export default WithRealms(WithActiveUserData(UploadButton));

export const activeDocumentsLimitReached = (incompleteDocumentsCount = 100, incompleteDocumentMaxAge = 100) => {
	let messages: string[] = [];
	if (DEFAULTS.maxIncompleteDocumentCount <= incompleteDocumentsCount)
		messages.push(
			`Ви досягли ліміту активних досліджень (${incompleteDocumentsCount} з ${DEFAULTS.maxIncompleteDocumentCount}). Завершіть редагування принаймні деяких з наявних та зверніться до свого керівника для схвалення виконаних.`
		);
	if (DEFAULTS.maxIncompleteDocumentAge <= incompleteDocumentMaxAge)
		messages.push(
			`Ви маєте прострочені активні дослідження. Найстаріше з них додано близько ${incompleteDocumentMaxAge} діб тому, що перевищує допустимий термін ${DEFAULTS.maxIncompleteDocumentAge} діб. Завершіть редагування прострочених досліджень та зверніться до свого керівника для схвалення виконаних.`
		);
	if (messages.length !== 0) {
		const message = (
			<>
				Наразі Ви не можете додавати нові дослідження за наступними причинами:
				<ul className="my-2">
					{messages.map((warning, index) => (
						<li key={index}>{warning}</li>
					))}
				</ul>
				Після цього Ви зможете додавати нові дослідження.
			</>
		);
		showError(message);
	}
	return messages.length !== 0;
};
