import { IEntity } from './common';

export interface IRealmDic {
	name: string;
	title: string;
	description: string;
	draft: boolean;
}
export interface ICountryDic {
	digram: string;
	trigram: string;
	title: string;
}

export interface IActiveUserData {
	editableRealms: string[];
	incompleteDocumentsCount: number;
	incompleteDocumentMaxAge: number;
}

export type TRealmDictionary = IEntity<string, IRealmDic>;
export type TCountryDictionary = IEntity<string, ICountryDic>;
export const ENTITY_STATE_STUB = { ids: [], entities: {} };

export type TRestrictionOptions = '0';
export type TTaskOptions = 'I' | 'C';
export type TPriorityOptions = '5' | '4' | '3' | '2';
export type TTaskTypeOptions = 'C' | 'I' | 'S';
export type TVersionOptions = '0' | '1';
export type TSeriesOptions = 'P' | 'E' | 'I';
export type TDisclaimerOptions = 'G' | 'O' | 'S' | 'R' | 'I';
export type TContainingOptions = 'D' | 'P' | 'I' | 'G' | 'V' | 'C' | 'L';
export type TSourceReliabilityOptions = 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
export type TPublicationReliabilityOptions = '1' | '2' | '3' | '4' | '5' | '6';
export type TDocumentStatusOptions = 'L' | 'W' | 'P' | 'D';
export type TStaticOptions = {
	RESTRICTION_OPTIONS?: { [key in TRestrictionOptions]: string };
	TASK_OPTIONS?: { [key in TTaskOptions]: string };
	PRIORITY_OPTIONS?: { [key in TPriorityOptions]: string };
	TASK_TYPE_OPTIONS?: { [key in TTaskTypeOptions]: string };
	VERSION_OPTIONS?: { [key in TVersionOptions]: string };
	SERIES_OPTIONS?: { [key in TSeriesOptions]: string };
	DISCLAIMER_OPTIONS?: { [key in TDisclaimerOptions]: string };
	CONTAINING_OPTIONS?: { [key in TContainingOptions]: string };
	SOURCE_RELIABILITY?: { [key in TSourceReliabilityOptions]: string };
	PUBLICATION_RELIABILITY?: { [key in TPublicationReliabilityOptions]: string };
	DOCUMENT_STATUS?: { [key in TDocumentStatusOptions]: string };
};
export interface IDictionaries {
	realms: TRealmDictionary;
	countries: TCountryDictionary;
	staticOptions: TStaticOptions;
}

export const PUB_RELIABILITY_DEFAULT = '6';
