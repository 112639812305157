import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { scrollWindowToTop } from '../scrolls';

interface IPageRange {
	pages: number[];
	showToFirst: boolean;
	showToLast: boolean;
}

interface ISearchPaginationProps extends React.AllHTMLAttributes<HTMLDivElement> {
	pageCount: number;
	activePageNo: number;
	onPageClick?: (newPageNo: number) => void;
	scrollWhenChanged?: boolean;
	pageDelta?: number;
	preText?: string | JSX.Element;
	showAlways?: boolean;
}
const SearchPagination = ({
	pageCount,
	activePageNo,
	onPageClick,
	className,
	scrollWhenChanged = true,
	pageDelta = 3,
	preText = '',
	showAlways = false,
}: ISearchPaginationProps) => {
	const [pageRange, setPageRange] = useState<IPageRange>({ pages: [], showToFirst: false, showToLast: false });

	useEffect(() => {
		let firstVisiblePage = activePageNo - pageDelta;
		const showToFirst = 0 < firstVisiblePage;
		if (!showToFirst) firstVisiblePage = 0;

		let lastVisiblePage = activePageNo + pageDelta;
		const showToLast = lastVisiblePage < pageCount - 1;
		if (!showToLast) lastVisiblePage = pageCount - 1;

		setPageRange({
			showToFirst,
			showToLast,
			pages: new Array(lastVisiblePage - firstVisiblePage + 1)
				.fill(true)
				.map((dummy, index) => index + firstVisiblePage),
		});
	}, [activePageNo, pageCount, pageDelta]);

	const onPage = (event: React.MouseEvent<HTMLAnchorElement>) => {
		const { pageNo } = ((event.target as HTMLElement).closest('.page-link') as HTMLAnchorElement).dataset;
		if (!pageNo) return;
		event.preventDefault();
		event.stopPropagation();
		onPageClick && onPageClick(parseInt(pageNo, 10));
		if (scrollWhenChanged) scrollWindowToTop();
	};

	if (pageCount < 2 && !showAlways) return null;

	return (
		<Pagination className={classNames('justify-content-center', className)}>
			{pageRange.showToFirst && (
				<>
					<Pagination.First data-page-no={0} onClick={onPage} />
					<Pagination.Ellipsis />
				</>
			)}
			{pageRange.pages.map((index) => (
				<Pagination.Item key={index} active={index === activePageNo} data-page-no={index} onClick={onPage}>
					{preText}
					{index + 1}
				</Pagination.Item>
			))}
			{pageRange.showToLast && (
				<>
					<Pagination.Ellipsis />
					<Pagination.Last data-page-no={pageCount - 1} onClick={onPage} />
				</>
			)}
		</Pagination>
	);
};

export default SearchPagination;
