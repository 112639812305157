import React, { useCallback, useEffect, useState } from 'react';
import { NavFace } from '../../styled';

const FACE_FLASHING_INTERVAL = 62 * 1000;
// const FACE_FLASHING_INTERVAL = 4 * 1000;
const FACE_CHANGING_INTERVAL = 15 * 60 * 1000;
// const FACE_CHANGING_INTERVAL = 10 * 1000;
const TRANSITION_INTERVAL = 500;

interface IRandomFaceProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const RandomFace = ({ children }: IRandomFaceProps) => {
	const [flashing, setFlashing] = useState(false);
	const [ts, setTs] = useState(new Date().valueOf());

	const startFlashing = useCallback(() => setFlashing(true), []);
	const stopFlashing = useCallback(() => setFlashing(false), []);
	const changeFace = () => setTs(new Date().valueOf());

	useEffect(() => {
		const intervalFlashing = setInterval(startFlashing, FACE_FLASHING_INTERVAL);
		const intercalChanging = setInterval(changeFace, FACE_CHANGING_INTERVAL);
		return () => {
			clearInterval(intervalFlashing);
			clearInterval(intercalChanging);
		};
	}, []); // eslint-disable-line

	useEffect(() => {
		if (!flashing) return;
		const tmFlashing = setTimeout(stopFlashing, TRANSITION_INTERVAL * 2);
		// setTimeout(setTs, TRANSITION_INTERVAL, new Date().valueOf());
		return () => {
			clearTimeout(tmFlashing);
		};
	}, [flashing]); // eslint-disable-line

	return (
		<NavFace>
			<img src={`/img/random_face.webp?ts=${ts}`} className={flashing ? 'nav-face_big ' : undefined} alt="Face" />
			{children}
		</NavFace>
	);
};

export default RandomFace;
