import React, { Suspense } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { BsFillInboxesFill, BsGear, BsPersonLinesFill, BsQuestionCircle, BsUpload } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DEFAULTS } from '../common';
// import UploadButton from '../features/uploads/UploadButton';
import RandomFace from '../features/facer/RandomFace';
import SearchForm from '../features/search/SearchForm';
import Logo from '../img/fire_1f525.png';
import ErrorBoundary from './ErrorBoundary';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import WithActiveUserData, { IWithActiveUserData } from './hoc/withActiveUserData';
import TextIcon from './textIcon';

const LazyUploadButton = React.lazy(() => import('../features/uploads/UploadButton'));

interface INavHeaderProps extends IWithActiveUserData, React.AllHTMLAttributes<HTMLDivElement> {
	isAuthenticated: boolean;
	isLoading: boolean;
	hasAccess: boolean;
	isAdmin: boolean;
}
const NavHeader = ({ isAuthenticated, isLoading, hasAccess, isAdmin, activeUserData }: INavHeaderProps) => {
	return (
		<Navbar bg="primary" variant="dark" expand="md" fixed="top" className="d-print-none">
			<Container fluid>
				<Link to="/" className="navbar-brand">
					<BrandImage src={Logo} className="d-inline-block" />
					{DEFAULTS.pageTitle}
				</Link>
				<Navbar.Toggle area-controls="navbarSupportedContent" />
				<Navbar.Collapse id="navbarSupportedContent">
					{hasAccess && (
						<>
							<Nav className="me-2">
								<NavDropdown
									title={
										<RandomFace>
											<span className="d-md-none d-lg-inline">Обличчя</span>
										</RandomFace>
									}
									id="ddFacer"
								>
									<NavDropdown.Item as={Link} to={`/${DEFAULTS.routes.facer}/${DEFAULTS.routes.facerRecognize}`}>
										Пошук за обличчям
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to={`/${DEFAULTS.routes.facer}/${DEFAULTS.routes.facerVerify}`}>
										Порівняння облич
									</NavDropdown.Item>
								</NavDropdown>
							</Nav>
							<SearchForm className="me-3" />
						</>
					)}
					<Nav>
						{activeUserData && activeUserData.editableRealms.length !== 0 && (
							// <UploadButton realms={activeUserData.editableRealms} />
							<ErrorBoundary>
								<Suspense
									fallback={
										<Nav.Link target="#" disabled>
											<BsUpload className="icon-lg" />
											<span className="ms-2 d-md-none d-lg-inline">Завантажити</span>
										</Nav.Link>
									}
								>
									<LazyUploadButton realms={activeUserData.editableRealms} />
								</Suspense>
							</ErrorBoundary>
						)}
						{isAdmin && (
							<NavDropdown
								title={
									<TextIcon inline Icon={BsGear} size="lg">
										<span className="d-md-none d-xl-inline">Адміністрування</span>
									</TextIcon>
								}
								id="ddAdmin"
							>
								<NavDropdown.Item as={Link} to={`/${DEFAULTS.routes.manage}/${DEFAULTS.routes.users}`}>
									<TextIcon Icon={BsPersonLinesFill}>Користувачі</TextIcon>
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to={`/${DEFAULTS.routes.manage}/${DEFAULTS.routes.sources}`}>
									<TextIcon Icon={BsFillInboxesFill}>Джерела</TextIcon>
								</NavDropdown.Item>
							</NavDropdown>
						)}
						<NavDropdown
							title={
								<TextIcon inline Icon={BsQuestionCircle} size="lg">
									<span className="d-md-none d-xxl-inline">Допомога</span>
								</TextIcon>
							}
							align="end"
						>
							<NavDropdown.Item as={Link} target="_blank" to="https://fsmgp.gitbook.io/moria/" rel="noreferrer">
								Інструкція з використання
							</NavDropdown.Item>
							{activeUserData?.editableRealms.length !== 0 && (
								<NavDropdown.Item
									as={Link}
									target="_blank"
									to="https://docs.google.com/document/d/1jWsiHx3YNqWLMbc-FP8T5AILluGTRqlNXDXbCaNaHho/edit?usp=sharing"
									rel="noreferrer"
								>
									Інструкція з публікації
								</NavDropdown.Item>
							)}
						</NavDropdown>
					</Nav>
					<Nav className="ms-auto">{isLoading || isAuthenticated ? <LogoutButton /> : <LoginButton />}</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default WithActiveUserData(NavHeader);

const BrandImage = styled.img`
	width: 40px;
	height: 40px 0;
	margin: -14px 0 -8px;
`;
