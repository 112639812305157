import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { BsArrowClockwise, BsArrowRepeat, BsCheckCircleFill, BsInfoCircle } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { DEFAULTS } from '../../../common';
import DotSpinner from '../../../components/DotSpinner';
import FetchError from '../../../components/FetchError';
import GrayerSkeleton from '../../../components/GreyerSkeleton';
import WithActiveUserData, { IWithActiveUserData } from '../../../components/hoc/withActiveUserData';
import WithDictionaries, { IWithDictionaries } from '../../../components/hoc/withDictionaries';
import TextIcon from '../../../components/textIcon';
import { useShowSkeleton } from '../../../hooks';
import { moveControlToScreenPosition } from '../../../scrolls';
import { RootState } from '../../../store';
import {
	addRecentDocumentsByPage,
	changeViewAllRecentDocs,
	clearRecentDocuments,
	incPageForLoad,
} from '../../search/searchSlice';
import { useDocumentsRecentQuery } from '../../services/documents';
import AddDocumentButton from '../AddDocumentButton';
import RecentDocumentList from './RecentDocumentList';

interface IRecentDocumentsState {
	pageNo: number; // номер останньої завантаженої сторінки
	documentId?: number; // ідентифікатор першого документу набору
	newDocumentsExist: boolean; // з'явились нові документи
}

interface IRecentDocumentsProps
	extends PropsFromRedux,
		IWithActiveUserData,
		IWithDictionaries,
		React.AllHTMLAttributes<HTMLDivElement> {}
const RecentDocuments = ({
	activeUserData,
	staticOptions: { DOCUMENT_STATUS },
	realms,
	viewAll,
	changeViewAllRecentDocs,
	recentDocuments,
	pageForLoad,
	addRecentDocumentsByPage,
	incPageForLoad,
	clearRecentDocuments,
}: IRecentDocumentsProps) => {
	const [notDraftEditableRealms, setNotDraftEditableRealms] = useState<string[]>(
		() => activeUserData?.editableRealms.filter((realm) => realms.entities[realm]?.draft === false) || []
	);
	const isNotDraftEditor = notDraftEditableRealms.length !== 0;
	const { data, isFetching, error, refetch } = useDocumentsRecentQuery(
		{ scope: viewAll ? 'all' : 'editable', pageNo: pageForLoad },
		{
			// pollingInterval: 30 * 1000,
			pollingInterval: 10 * 60 * 1000,
			skip: viewAll === undefined,
		}
	);
	const [state, setState] = useState<IRecentDocumentsState>({ pageNo: -1, newDocumentsExist: false });
	const showSkeleton = useShowSkeleton(isFetching, 200);

	const reloadDocuments = () => {
		if (pageForLoad === 0) refetch();
		else clearRecentDocuments();
	};

	useEffect(() => {
		setNotDraftEditableRealms(
			() => activeUserData?.editableRealms.filter((realm) => realms.entities[realm]?.draft === false) || []
		);
	}, [activeUserData, realms]);

	useEffect(() => {
		if (viewAll !== undefined || !activeUserData || realms.ids.length === 0) return;
		changeViewAllRecentDocs(!activeUserData.editableRealms.some((realm) => realms.entities[realm]?.draft === false));
	}, [activeUserData, realms]); // eslint-disable-line

	useEffect(() => {
		if (!data) return;
		const { pageNo, documents } = data;
		const firstDocumentId = documents[0]?.id;

		// Якщо завантажена нова сторінка документів, то скролим сторінку браузера вгору.
		if (recentDocuments[pageNo] === undefined) {
			setTimeout(() => {
				const control = document.querySelector(
					`.recent-document__table tr:nth-child(${pageForLoad * DEFAULTS.recentDocumentsPerPage + 1})`
				);
				moveControlToScreenPosition(control, 'center');
			}, 50);
		}

		// Оновлювати потрібно тільки якщо це перша, або нова сторінка, інакше будуть артефакти
		// у вигляді повторів документів.
		if (pageNo === 0 || pageNo !== state.pageNo) addRecentDocumentsByPage({ page: pageNo, documents });
		setState((state) => ({
			pageNo,
			// documentId: documents.length !== 0 ? documents[0].id : undefined,
			documentId: firstDocumentId,
			/**
			 * Вважаємо, що існують нові документи якщо вони вже існували, або в результаті полінгу
			 * (тобто без зміни номеру сторінки) змінився ідентифікатор першого документа.
			 * Якщо ми завантажили першу сторінку, то список вже оновлений (додаткове оновлення не потрібне)
			 */
			newDocumentsExist: pageNo === 0 ? false : pageNo === state.pageNo && firstDocumentId !== state.documentId,
		}));
	}, [data]); // eslint-disable-line

	// useEffect(() => {
	// 	document.addEventListener(DEFAULTS.events.updateRecentList, refetch);
	// 	return () => document.removeEventListener(DEFAULTS.events.updateRecentList, refetch);
	// }, [refetch]);

	const onChangeScopeChange = () => changeViewAllRecentDocs(!viewAll);

	const onLoadMoreClick = () => incPageForLoad();

	return (
		<>
			{isNotDraftEditor && (
				<AddDocumentButton
					realms={realms}
					editableRealms={notDraftEditableRealms}
					incompleteDocumentsCount={activeUserData?.incompleteDocumentsCount}
					incompleteDocumentMaxAge={activeUserData?.incompleteDocumentMaxAge}
				/>
			)}
			{error && <FetchError error={error} />}
			{isNotDraftEditor && (
				<Form.Check
					type="switch"
					id="cbAllDocs"
					label="Показувати документи, створені усіма користувачами"
					checked={!!viewAll}
					onChange={onChangeScopeChange}
					className="mb-2"
					disabled={isFetching}
				/>
			)}
			{state.newDocumentsExist && (
				<Button variant="info" className="w-100 rounded-0" onClick={reloadDocuments}>
					Додано нові документи
					<BsArrowRepeat className="mx-2" />
					Натисніть для оновлення
				</Button>
			)}
			<Table borderless striped className="lh-sm table-smart-border recent-document__table">
				<tbody>
					{recentDocuments.map((documents, index) => (
						<RecentDocumentList
							key={viewAll + '_' + index}
							documents={documents}
							isNotDraftEditor={isNotDraftEditor}
							DOCUMENT_STATUS={DOCUMENT_STATUS}
							indexBase={index * DEFAULTS.recentDocumentsPerPage}
							isFetching={isFetching}
						/>
					))}
					{showSkeleton &&
						new Array(5).fill(undefined).map((_, index) => (
							<tr key={'skeleton_' + index}>
								<td width="30px">
									<GrayerSkeleton />
								</td>
								<td>
									<GrayerSkeleton count={Math.random() * 0.7 + 0.3} />
								</td>
								<td width="90px">
									<GrayerSkeleton />
								</td>
								<td width="50px">
									<GrayerSkeleton />
								</td>
								<td width="150px">
									<GrayerSkeleton count={Math.random() * 0.6 + 0.4} />
								</td>
							</tr>
						))}
				</tbody>
			</Table>
			{recentDocuments.length === 0 && isFetching && (
				<DotSpinner className="mb-2">Завантаження останніх доданих документів...</DotSpinner>
			)}
			{recentDocuments.length !== 0 &&
				(recentDocuments[recentDocuments.length - 1].length !== 0 ? (
					<Button variant="outline-primary mb-3" disabled={isFetching} onClick={onLoadMoreClick}>
						<TextIcon Icon={BsArrowClockwise} className={classNames('icon-lg', isFetching && 'animation-spin')}>
							Показати ще
						</TextIcon>
					</Button>
				) : (
					<div className="text-center text-secondary mb-3">
						{/* <img
							src="https://images.roadtrafficsigns.com/img/sm/K/road-ends-diamond-sign-k-0944.png"
							style={{ width: 60, height: 60 }}
						/> */}
						<BsCheckCircleFill className="opacity-25 icon-lg me-2" />
						{/* <BsXDiamondFill className="text-warning opacity-50 icon-lg" /> */}
						{/* <span className="text-bg-warning p-2 rounded border border-2">Кінець повідомлень</span> */}
					</div>
				))}
			<div className="text-secondary mt-n1 mb-2">
				<BsInfoCircle /> Список складається лише з документів, які містять результати досліджень. Документи з первинними
				матеріалами в список не включено.
			</div>
		</>
	);
};

const mapState = (state: RootState) => ({
	viewAll: state.search.viewAllRecentDocs,
	recentDocuments: state.search.recentDocuments,
	pageForLoad: state.search.pageForLoad,
});

const mapDispatch = {
	changeViewAllRecentDocs,
	addRecentDocumentsByPage,
	clearRecentDocuments,
	incPageForLoad,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WithDictionaries(WithActiveUserData(RecentDocuments)));
