import React from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import QuestionButton from '../../components/QuestionButton';

interface IRangeHelperTextProps extends React.AllHTMLAttributes<HTMLDivElement> {
	value?: string;
	hint?: string;
}
const RangeHelperText = ({ disabled, value, hint }: IRangeHelperTextProps) => {
	return (
		<div className="d-flex justify-content-between small-text text-muted mt-n3">
			<span>
				<BsArrowLeft className="me-1" />
				Нижча
			</span>
			{value !== undefined && (
				<span className={disabled ? 'text-danger' : undefined}>
					Поточна: {value}
					{disabled && hint && <QuestionButton title={hint} className="ms-1 mt-n1" />}
				</span>
			)}
			<span>
				Вища
				<BsArrowRight className="ms-1" />
			</span>
		</div>
	);
};

export default RangeHelperText;
