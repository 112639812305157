import React, { ForwardedRef, forwardRef, useState } from 'react';
import { useAppDispatch } from '../../store';
import { clearRecentDocuments } from '../search/searchSlice';
import Uploader from './Uploader';

interface IUploaderFileSelectorProps extends React.AllHTMLAttributes<HTMLDivElement> {
	onUploadDone: (documentHash?: string) => void;
	realm: string;
	isDraft: boolean;
	autoClose?: boolean;
	/**
	 * Якщо передано documentId, то завантажений документ додається до набору, що вже існує
	 */
	documentId?: number;
	documentNo?: number;
}
const UploaderFileSelector = forwardRef(
	(
		{ onUploadDone, realm, isDraft, autoClose = false, documentId, documentNo, title }: IUploaderFileSelectorProps,
		refFileSelector: ForwardedRef<HTMLInputElement>
	) => {
		const dispatch = useAppDispatch();
		const [files, setFiles] = useState<FileList | null | undefined>();

		const onInputFilesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
			event.preventDefault();
			event.stopPropagation();
			setFiles((refFileSelector as React.RefObject<HTMLInputElement>).current?.files);
		};

		const onFilesUploaded = (documentHash?: string) => {
			setFiles(undefined);
			if (refFileSelector && (refFileSelector as any).current) (refFileSelector as any).current.value = '';
			// const updateRecentListEvent = new CustomEvent(DEFAULTS.events.updateRecentList);
			// document.dispatchEvent(updateRecentListEvent);
			// Очищуємо список останніх документів тільки якщо було завантажено дослідження
			if (documentHash) dispatch(clearRecentDocuments());
			onUploadDone(documentHash);
		};

		return (
			<>
				<input
					ref={refFileSelector}
					type="file"
					id="fileUpload"
					name="fileUpload"
					accept={
						isDraft
							? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/msword'
							: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
					}
					multiple={isDraft}
					autoComplete="off"
					className="d-none"
					onChange={onInputFilesChange}
				/>
				{files && (
					<Uploader
						files={files}
						onUploadDone={onFilesUploaded}
						realm={realm}
						title={title}
						autoClose={autoClose}
						isDraft={isDraft}
						documentId={documentId}
						documentNo={documentNo}
					/>
				)}
			</>
		);
	}
);

export default UploaderFileSelector;
