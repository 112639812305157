import { Navbar } from 'react-bootstrap';
import { BsEnvelope } from 'react-icons/bs';
import styled from 'styled-components';
import brandLogo from '../img/jack-o-lantern_1f383.png';
import { TColorTheme } from '../interfaces/common';
import ShortStatistic from './ShortStatistic';

interface INavFooterProps extends React.AllHTMLAttributes<HTMLDivElement> {
	hasAccess: boolean;
	colorTheme: TColorTheme;
}
const NavFooter = ({ hasAccess, colorTheme }: INavFooterProps) => {
	const isLightTheme = colorTheme === 'light';

	return (
		<Navbar
			bg={isLightTheme ? 'light' : 'dark'}
			variant="light"
			className="mt-auto"
			data-bs-theme={isLightTheme ? undefined : 'dark'}
		>
			<FooterContainer className="container-fluid flex-wrap text-secondary">
				<a
					href={`https://${process.env.REACT_APP_DOMAIN_NAME}`}
					className="d-flex align-items-center text-decoration-none"
				>
					<BrandImage alt="Brand" src={brandLogo} className="me-2" />
					<span className="navbar-text">
						Фундація стратегічного моніторингу
						<br />
						загальних процесів
					</span>
				</a>
				{hasAccess && (
					<>
						<ShortStatistic />
						<div>
							<BsEnvelope className="me-1 mt-n1" />
							Зворотній зв'язок:
							<br />
							<span className="semi-bold">{process.env.REACT_APP_ADMIN_EMAIL}</span>
						</div>
					</>
				)}
			</FooterContainer>
		</Navbar>
	);
};

const BrandImage = styled.img`
	width: 50px;
	height: 50px;
	margin-top: -4px;
`;

const FooterContainer = styled.div`
	margin-right: 36px;
	gap: 0.5em 1.5rem;
`;

export default NavFooter;
