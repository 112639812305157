import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

export type TStringWithUndefined = string | undefined;
export type TNumberWithUndefined = number | undefined;
export type TStringOrNull = string | null;
export type TNumberOrNull = number | null;
export type THighlighting = { [key: string]: { body: string[] } };

export interface IDefaultHttpResponse {
	success: number;
}
export interface IUpdateResponse extends IDefaultHttpResponse {
	ID: number;
	solrId?: string;
}

export interface IAuth0AccessToken {
	scope: string;
	iat: number;
	exp: number;
}

export interface IToast {
	id: number;
	title?: string;
	text: string | JSX.Element;
	variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
	delay?: number;
}

export interface ILogItem {
	hitDate: Date;
	success: boolean;
	fileName: string;
	message: string;
}

export const permissionExists = (permissionForCheck: string, permissions: string[] = []) =>
	permissions.includes(permissionForCheck);

export const formatDocumentDate = (strDate?: string) => (strDate ? new Date(strDate).toLocaleDateString() : '');

export const getCurrentIsoDate = () => new Date().toISOString().split('T')[0];

export const getDigits = (s: string) =>
	s
		.split('')
		.filter((c) => /\d/.test(c))
		.join('');

export const promiseTimeout = (ms: number) =>
	new Promise((resolve) =>
		setTimeout(() => {
			resolve(undefined);
		}, ms)
	);

export const getPassportNumberFromString = (s: string) => {
	const m = s.match(/([a-zа-яієї]{2})(?:\s|-)*((?:\d(?:\s|-)*){6})/i);
	if (!m || m.length < 3) return ['', ''];
	return [m[1], m[2].replace(/\s+|-/g, '')];
};

export const getAutoNumberFromString = (s: string) => {
	const m = s.match(/([a-zа-яієї]{2})?(?:\s|-)*((?:\d(?:\s|-)*){4,5})(?:\s|-)*([a-zа-яієї]{2})?/i);
	if (!m || m.length < 4) return ['', '', ''];
	return [m[1], m[2].replace(/\s+|-/g, ''), m[3]];
};

// SearchParams

export const enum ESearchScope {
	essCommon = '-',
	essPhone = 'T',
	essAutoNumber = 'A',
	essPassport = 'P',
}

export interface ISearchParams {
	term: string;
	scope: ESearchScope;
	severity: string;
	realm: string;
}

export interface IProcessedSearchParams extends ISearchParams {
	pageNo: number;
}

// Фрази
export const EXIT_CONFIRMATION = 'Ви бажаєте вийти без збереження змін?';
export const DELETION_WARNING =
	'Після підтвердження цю операцію неможливо буде скасувати, тож будьте впевнені в своїх діях!';

/**
 * It gets the value of a search parameter from the URL.
 * @param {URLSearchParams} searchParams - the URLSearchParams object that contains the search
 * parameters
 * @param paramName - the name of the parameter to get from the searchParams
 */
export const getSearchParam = (searchParams: URLSearchParams, paramName: keyof ISearchParams) =>
	searchParams.get(paramName) || DEFAULTS.searchParams[paramName] || '';

/**
 * It takes an object and removes all the properties that are equal to the same property in default object
 * @param params - The parameters that you want to remove the default parameters from.
 * @param {T} defaultParams - The default parameters that you want to remove from the params object.
 * @returns Повертає об'єкт з полями, які не дорівнюють параметрам по замовчанню
 */
export const removeDefaultParams = <T>(params: Partial<T>, defaultParams: T): Partial<T> => {
	const notDefaultParams = { ...params };
	for (const key in params) {
		if (params[key] === defaultParams[key]) delete notDefaultParams[key];
	}
	return notDefaultParams;
};

/**
 * It takes a URLSearchParams object and returns an object with the same keys and values
 * @param {URLSearchParams} searchParams - URLSearchParams - the search parameters from the URL
 * @returns An object with the keys and values of the search params.
 */
export const getObjectSearchParams = <T>(searchParams: URLSearchParams, defaults: T): T => {
	const result = { ...defaults };
	for (const [key, value] of searchParams.entries() as any) {
		result[key as keyof T] = value || '';
	}
	return result;
};

export type TEntityEditMethod = 'POST' | 'PUT' | 'DELETE';

export const DEFAULTS = {
	pageTitle: 'Кузня',
	accessPermissionName: 'read:moria',
	// editPermissionName: 'edit:moria',
	adminPermissionName: 'admin:moria',
	availableScopes: 'read:moria admin:moria',
	locale: 'uk-UA',
	searchParams: {
		term: '',
		scope: ESearchScope.essCommon,
		severity: '100',
		realm: '*',
	} as ISearchParams,
	documentsPerPage: process.env.NODE_ENV === 'production' ? 20 : 10,
	sourcesPerPage: process.env.NODE_ENV === 'production' ? 25 : 5,
	recentDocumentsPerPage: process.env.NODE_ENV === 'production' ? 25 : 5,
	maxIncompleteDocumentCount: 10,
	maxIncompleteDocumentAge: 30, // days
	fluid: 'xxl',
	uploadFileMaxSize: parseInt(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE || '15e6', 10),
	screenMdBorder: 768,
	fromListState: { fromList: true },
	FIXED_TOP_POSITION: 56,
	routes: {
		manage: 'manage',
		users: 'users',
		sources: 'sources',
		doc: 'doc',
		docs: 'docs',
		edit: 'edit',
		facer: 'facer',
		facerRecognize: 'recognize',
		facerVerify: 'verify',
	},
	httpMethod: {
		post: 'POST',
		put: 'PUT',
		delete: 'DELETE',
	} as { [key: string]: TEntityEditMethod },
	noMatch: 'збіги відсутні',
	updateErrorText: 'Помилка оновлення',
	// events: {
	// 	updateRecentList: 'updateRecentList',
	// },
};

export const getTokenSilentlyAuthOptions = (
	scope = DEFAULTS.accessPermissionName,
	withUserData = false
): GetTokenSilentlyOptions => {
	return {
		authorizationParams: {
			audience:
				process.env.NODE_ENV === 'production'
					? (process.env.REACT_APP_PROD_AUTH0_AUDIENCE as string)
					: (process.env.REACT_APP_DEV_AUTH0_AUDIENCE as string),
			scope: scope + (withUserData ? ' profile email offline_access' : ''),
		},
	};
};
