import { Storage } from '../classes/storage';
import { TEntityEditMethod } from '../common';

// Entities

type TStringOrNumber = string | number;

export type TEntityId<T extends TStringOrNumber> = T;

export type IDictionary<K extends TStringOrNumber, T> = {
	[id in K]: T | undefined;
};

export interface IEntity<K extends TStringOrNumber, T> {
	ids: TEntityId<K>[];
	entities: IDictionary<K, T>;
}

export type TColorTheme = 'light' | 'dark';

export type TObjectType = { [key: string]: any };

export const QUERY_TAGS = {
	user: 'user',
	source: 'source',
	document_by_hash: 'doc_hash',
	document_by_serial: 'doc_serial',
};
/**
 * Storage
 */

interface IMoriaStorage {
	realm: string;
	theme: TColorTheme;
	stc: number;
}
export const storage = new Storage<IMoriaStorage>('moria');

export const updateThemeInPage = (theme?: TColorTheme) => {
	if (!theme) theme = (storage.get('theme') || 'light') as TColorTheme;
	document.documentElement.dataset.bsTheme = theme;
};

export interface IEntityEditMethod {
	method: TEntityEditMethod;
}

export type TMutatingRow<T, F extends keyof T> = Partial<T> & Pick<T, F>;

export interface ISelectOption {
	label: string;
	value: string;
}
