import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { showError, showPromisePrompt } from '../../alerts';
import WithRealms, { IWithRealms } from '../../components/hoc/withRealms';
import TextIcon from '../../components/textIcon';
import { TEntityId } from '../../interfaces/common';
import { TRealmDictionary } from '../../interfaces/dict';
import { useAppDispatch } from '../../store';
import { clearRecentDocuments } from '../search/searchSlice';
import { IUploadResult, useAddEmptyDocumentMutation } from '../services/documents';
import { activeDocumentsLimitReached } from '../uploads/UploadButton';

interface IAddDocumentButtonProps extends IWithRealms {
	editableRealms: TEntityId<string>[];
	realms: TRealmDictionary;
	incompleteDocumentsCount?: number;
	incompleteDocumentMaxAge?: number;
}
const AddDocumentButton = ({
	editableRealms,
	realms,
	incompleteDocumentsCount,
	incompleteDocumentMaxAge,
}: IAddDocumentButtonProps) => {
	const dispatch = useAppDispatch();
	const [addEmptyDocument] = useAddEmptyDocumentMutation();
	const navigate = useNavigate();

	const onClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
		const { realm } = event.currentTarget.dataset;
		if (!realm) return;
		// event.preventDefault();
		// event.stopPropagation();

		if (activeDocumentsLimitReached(incompleteDocumentsCount, incompleteDocumentMaxAge)) return;

		const title = (await showPromisePrompt('Введіть назву нового дослідження'))?.trim();
		if (!title) return;

		const result = await addEmptyDocument({ realm, title });

		if ((result as any).error) return showError((result as any).error.data || JSON.stringify((result as any).error));
		if (((result as any).data as IUploadResult).documentId) {
			navigate(`/doc/${((result as any).data as IUploadResult).documentId}`);
			setTimeout(() => dispatch(clearRecentDocuments()), 200);
		}
	};

	return (
		<DropdownButton
			variant="outline-primary"
			className="position-absolute top-o end-0"
			size="sm"
			align="end"
			title={
				<TextIcon Icon={BsPlusCircle} inline>
					<span className="d-none d-md-inline">Нове дослідження</span>
				</TextIcon>
			}
			id="ddNewDocument"
		>
			{editableRealms.map((name) => (
				<Dropdown.Item key={name} href="#" onClick={onClick} data-realm={name}>
					{realms.entities[name]?.title}
				</Dropdown.Item>
			))}
		</DropdownButton>
	);
};

export default WithRealms(AddDocumentButton);
